.savedSearch {
  &__save {
    &-container {
      display: flex;
      justify-content: center;
    }
  }

  &__error {
    margin: 2px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border: 0.8px solid var(--lcui-greyLight);
    border-radius: 8px;
    padding: 20px 17px;
    align-items: center;
    justify-content: center;

    &__text {
      text-align: center;
    }

    &__icon {
      margin: auto;

      &-container {
        // padding:10px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 30px;
        background-color: var(--lcui-danger--100);
        border-radius: 50%;
        border: 1px solid var(--lcui-danger--200);
      }
    }
  }

  &__success {
    margin: 2px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border: 0.8px solid var(--lcui-greyLight);
    border-radius: 8px;
    padding: 20px 17px;
    align-items: center;
    justify-content: center;

    &__text {
      text-align: center;
    }

    &__icon {
      margin: auto;

      &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 30px;
        background-color: var(--lcui-success--100);
        border-radius: 50%;
        border: 1px solid var(--lcui-success--200);
      }
    }
  }
}

.saved-search-button {
  width: inherit;

  &.filters-page {
    width: 50%;
  }
}
