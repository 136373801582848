.filterTagsContainer {
  &__header {
    display: flex;
    justify-content: space-between;

    &.appMobile {
      padding-top: 0;
    }
  }

  &__seeMoreFilterTags {
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;
    margin: 8px 0;
  }
}
