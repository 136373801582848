.filterTags {
  display: flex;
  padding-top: 12px;

  &__listingPage {
    gap: 8px;
    flex-wrap: wrap;
  }

  &__filterPage {
    flex-wrap: nowrap;
    overflow: scroll;
    white-space: nowrap;
    gap: 12px;
    position: relative;
    -ms-overflow-style: none; /* hide scroll bar for IE and Edge */
    scrollbar-width: none; /* hide scroll bar for Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(0 0 0 / 11%);
      border-radius: 5px;
    }

    &.appMobile {
      padding-bottom: 0;
    }
  }
}

.searchTag {
  > .Tag_Tag_clickable,
  .Text_Text_text.Text_Text_bold {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
