.search-save {
  background-color: transparent;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--lcui-black);
  text-decoration: underline;

  &__disabled {
    color: var(--lcui-greyLight);
    cursor: default;
  }
}
